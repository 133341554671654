* {
  list-style: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.nav-link {
  transition: 0.2s;
}

.active-link {
  border-left: 2px black solid;
  padding: 10px;
}
.image-input-file {
  transition: 0.2s;
}
.image-input-file:hover {
  filter: brightness(50%);
}

.map-data {
  width: 100%;
}

@media (min-width: 992px) {
  .map-data {
    width: 25%;
  }

  .map-data {
    height: 100%;
  }
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.collapsible-content.expanded {
  max-height: 1000px; /* Choisissez une valeur assez grande pour contenir tout le contenu */
}

.collapsible-container {
  border-top: 1px solid #ddd;
  padding: 0;
}

.collapsible-header {
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.collapsible-body {
  padding: 1rem;
}

.sidebar {
  height: 100%;
  width: 0;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}

.sidebar-open {
  width: 250px;
}

.sidebar a {
  display: block;
  transition: 0.3s;
  white-space: nowrap;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .sidebar {
    height: 0;
    transition: height 0.5s;
  }
  .sidebar-open {
    height: 450px;
  }
  #main {
    margin-left: 0;
  }
}

.text-multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 3em;
}
